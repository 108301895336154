import React from 'react';

const Gallery: React.FC = () => {
    const catPhotos = [
        'IMG_20171219_162310.jpg',
        'IMG_20180110_192008.jpg',
        'IMG_20180113_180921.jpg',
        'IMG_20180110_190124.jpg'
    ];

    return (
        <div>
            <h2>Gallery</h2>
            <div className="gallery">
                {catPhotos.map((photo, index) => (
                    <img key={index} src={require(`../assets/${photo}`)} alt={`Cat ${index + 1}`} />
                ))}
            </div>
        </div>
    );
}

export default Gallery;
