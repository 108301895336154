import styled, {createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
  }

  p {
    margin-top: 0;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const Content = styled.div`
  // top right bottom left
  padding: 0 10px 10px 10px;
`;

export const Button = styled.button`
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  padding: 0.938em;
  border: none;
  border-radius: 4px;
  background-color: #22223B;
  color: #fefefe;

  &:hover {
    background-color: #4A4E69;
    color: #fefefe;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  text-align: center;
`;
