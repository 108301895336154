import React from 'react';

const Prices: React.FC = () => {
    return (
        <div>
            <h2>Prices</h2>
            <ul>
                <li>One cat: $25 per night</li>
                <li>Two cats: $45 per night</li>
                <li>Three cats: $55 per night</li>
            </ul>
        </div>
    );
}

export default Prices;
