import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 768px) {
    /* For desktop: */
    width: 50%;
  }
`;

export const Label = styled.label`
  margin-bottom: 0.5em;
  color: #444;
  font-weight: lighter;
`;

export const Input = styled.input`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;

  // top right bottom left
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #d6d1d5;
  margin-top: 5px;
`;

export const Textarea = styled.textarea`
  // top right bottom left
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  resize: vertical;
`;

export const FormState = styled.div`
  padding-bottom: 5px;
  color: #49ab74;
`;

export const FormError = styled.p`
  padding-bottom: 5px;
  color: #db2269;
  font-size: 0.8em;

  &:before {
    display: inline;
    content: "⚠ ";
  }
`;
