import React from 'react';

const Home: React.FC = () => {
    return (
        <div>
            <h2>Welcome to Cat Hotel</h2>
            <p>Your cat's home away from home.</p>
            <div className="gallery">
            <img src={require('../assets/IMG_20180110_185704.jpg')} alt={'Welcome cat'}/>
            </div>
        </div>
    );
}

export default Home;
