import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Prices from './components/Prices';
import Gallery from './components/Gallery';
import './App.css';
import Contact from "./pages/Contact";

const App: React.FC = () => {
    return (
        <Router>
            <div className="App">
                <Header/>
                <main>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/prices" element={<Prices/>}/>
                        <Route path="/gallery" element={<Gallery/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                    </Routes>
                </main>
                {/*<Footer/>*/}
            </div>
        </Router>
    );
}

export default App;
